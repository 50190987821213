<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh">
    <l-description :option="descOption" :data="descData"></l-description>
    <el-row>
      <el-col>
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>基本信息</span>
            </div>
          </template>
          <table class="table-detail">
            <tr>
              <th>日报日期</th>
              <td>{{ dataInfo.reportDate }}</td>
              <th>提交人</th>
              <td>{{ dataInfo.userName }}</td>
              <th>所属部门</th>
              <td>{{ dataInfo.departName }}</td>
            </tr>
            <tr>
              <th>是否上班</th>
              <td>{{ dataInfo.workFlag == 0 ? '不上班' : '上班' }}</td>
              <th>是否发热咳嗽</th>
              <td>
                <span v-if="dataInfo.frksFlag == 0">无</span>
                <span v-else class="text-red">有</span>
              </td>
              <th>体温</th>
              <td>
                <span v-if="dataInfo.temp > 37.2" class="text-red"> {{ dataInfo.temp }}℃</span>
                <span v-else> {{ dataInfo.temp }}℃</span>
              </td>
            </tr>
            <tr>
              <th>行程码颜色</th>
              <td>
                <span v-if="dataInfo.xcmColor == 'G'" class="text-green">绿色</span>
                <span v-else-if="dataInfo.xcmColor == 'Y'" class="text-yellow">黄色</span>
                <span v-else-if="dataInfo.xcmColor == 'R'" class="text-red">红色</span>
              </td>
              <th>苏康码颜色</th>
              <td>
                <span v-if="dataInfo.skmColor == 'G'" class="text-green">绿色</span>
                <span v-else-if="dataInfo.skmColor == 'Y'" class="text-yellow">黄色</span>
                <span v-else-if="dataInfo.skmColor == 'R'" class="text-red">红色</span>
              </td>
              <th>是否离宁</th>
              <td>
                <span v-if="dataInfo.isExist == 0">无</span>
                <span v-else class="text-red">有</span>
              </td>
            </tr>
            <tr>
              <th>是否出入中高险地区</th>
              <td>
                <span v-if="dataInfo.isDangerArea == 0">无</span>
                <span v-else class="text-red">有</span>
              </td>
              <th>备注</th>
              <td colspan="3">{{ dataInfo.remark }}</td>
            </tr>
          </table>
        </el-card>
        <el-row>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>行程码图片</span>
                </div>
              </template>
              <div class="padding-xs text-center">
                <el-image :src="dataInfo.xcmImgUrl"></el-image>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>苏康码图片</span>
                </div>
              </template>
              <div class="padding-xs  text-center">
                <el-image :src="dataInfo.skmImgUrl"></el-image>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="margin-xs">
              <template #header>
                <div class="card-header">
                  <span>核酸检测</span>
                </div>
              </template>
              <div class="padding-xs  text-center">
                <el-image :src="dataInfo.hsjcImgUrl"></el-image>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    evOrder: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      imgList: null,
      preImgList: [],
      panelStyle: { height: document.documentElement.clientHeight * 0.9 - 200 + 'px', overflow: 'auto' },
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '记录人', prop: 'recordUser' },
          { label: '内容', prop: 'recordContent' },
          { label: '时间', prop: 'recordTime' }
        ]
      },
      recordList: null
    }
  },
  mounted() {
    this.getRecordList()
  },
  methods: {
    getRecordList() {
      this.$lgh.get('api/enterprise/advice/recordlist', { adviceId: this.dataInfo.adviceId }).then(res => {
        this.recordList = res
      })
    }
  }
}
</script>

<style lang="less" scoped>
.fileIcon {
  height: 20px;
  width: 20px;
}

.fileitem {
  a {
    text-decoration: underline;
    cursor: pointer;
    color: #409eff;
  }
}
</style>
